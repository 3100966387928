import store from '@/modules/adaptedStorage';

export default function(x: string, defaultValue?: any) {

	const pieces = x.split('.');

	let obj = store.state.userData || {};

	while (pieces.length) {
		const next = pieces.shift() as string;


		if (typeof obj[next] === 'undefined') return defaultValue ?? null;
		obj = obj[next];
	}

	return obj;
}