
import CyanPageWrapper, {
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import { IonButton,IonIcon, IonPage } from "@ionic/vue";
import { megaphoneOutline, helpBuoyOutline, trophyOutline } from 'ionicons/icons';
import UserData from "@/components/UserData.vue";
import StreamlinedHome from "@/components/StreamlinedHome.vue"
import BotonDirectorioStreamlined from '@/components/BotonDirectorioStreamlined.vue';
export default defineComponent({
  name: "Home",
  components: {
    IonButton,
    IonIcon,
    IonPage,
    BotonDirectorioStreamlined,
    CyanPageWrapper,
    UserData,
    StreamlinedHome,
  },
  setup() {
    return {
      megaphoneOutline,
      trophyOutline,
      helpBuoyOutline
    }
  },
  computed: {
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p('busl') && !p('jrsl') && !p('pcsl');
    }
  },
  methods: {},
});
