
import { defineComponent } from "vue";

export default defineComponent({
  name: "StreamlinedButton",
  components: {},
  setup() {
    return {};
  },
  computed: {},
  methods: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
    back: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    azul: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
    withSpinner: {
      type: Boolean,
      default: true,
    },
  },
});
