
import store from "@/modules/adaptedStorage";
import { defineComponent } from "vue";
import { alertController } from "@ionic/vue";
import ud from "@/modules/cyanUserData.ts";
import cyanRequest from "@/modules/cyanRequest";
import StreamlinedButton from "./StreamlinedButton.vue";
import { municipios } from "@/modules/cyanRegions";
import { geoAccion, obtenerUbicacion } from '@/modules/geoAccion';
import cyanGetPhoto from '@/modules/cyanGetPhoto';

export default defineComponent({
  name: "StreamlinedHome",
  components: {
    StreamlinedButton,
  },
  data() {
    return {
      loadingFlags: {
        llegada: false,
        jrslapertura: false,
        pcslcanopy: false,
        pcslcierre: false,
      },
    };
  },
  setup() {
    return {
      geoAccion
    };
  },
  computed: {
    esAzul() {
      const _t = this as any;
      return _t.bu && !_t.pc && !_t.jr;
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    jrvCerradas() {
      const cierres = ud('cierreJRV');
      for (const i in cierres) {
        if (!cierres[i]) return false;
      }
      return true;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    haLlegado() {
      if (this.pc && !ud("cyanLlegada")) return false;
      if (this.bu && !ud("encuestadorLlegada")) return false;
      if (this.jr && !ud("llegada")) return false;
      return true;
    },
    pc() {
      return store.getters.userHasPermission("pcsl");
    },
    bu() {
      return store.getters.userHasPermission("busl");
    },
    jr() {
      return store.getters.userHasPermission("jrsl");
    },
  },
  methods: {
    ud(x: string) {
      return ud(x);
    },

    async llegada() {
      if (this.loadingFlags.llegada || this.haLlegado) return false;
      this.loadingFlags.llegada = true;
      const geo = await this.geoAccion(
        "Va a indicar que ha llegado físicamente al centro de votación. ¿Es correcto?",
        ''
//        "No se pudo obtener su ubicación. Puede notificar su llegada sin indicar ubicación, o reintentarlo más tarde."
      );

      if (geo && geo.confirmed) {
        if (store.getters.userHasPermission("jrsl")) {
          const r = await cyanRequest("jrsl/llegada", {
            isPost: true,
            params: geo,
            needsAuth: true,
            retries: 3,
          });
          if (r.ok) {
            store.commit("patchUserData", r);
          } else {
            this.loadingFlags.llegada = false;
            return;
          }
        }
        if (store.getters.userHasPermission("pcsl")) {
          const r = await cyanRequest("pcsl/cyanLlegada", {
            isPost: true,
            needsAuth: true,
            params: geo,
            retries: 3,
          });
          if (r.ok) {
            store.commit("patchUserData", r);
          }
        }
        if (store.getters.userHasPermission("busl")) {
          const r = await cyanRequest("busl/encuestadorLlegada", {
            isPost: true,
            needsAuth: true,
            params: geo,
            retries: 3,
          });
          if (r.ok) {
            store.commit("patchUserData", r);
          }
        }
      }
      this.loadingFlags.llegada = false;
    },

    async abrirCentro() {
      return (this as any).geoAccionBoton(
        "el centro ha sido aperturado",
        "jrsl/apertura"
      );
    },
    async cerrarCentro() {
      return (this as any).geoAccionBoton(
        "el centro ha sido cerrado",
        "pcsl/cierre"
      );
    },
    async abrirCentroEncuestador() {
      return (this as any).geoAccionBoton(
        "el centro ha sido aperturado",
        "busl/encuestadorApertura"
      );
    },
    async cerrarCentroEncuestador() {
      return (this as any).geoAccionBoton(
        "el centro ha sido cerrado",
        "busl/encuestadorCierre"
      );
    },
    async instalarCanopy() {

      const loadingflag = 'pcslcanopy';

      const datos = ud('canopy');

      if (datos) {
        const mensaje = 'El Canopy ha sido instalado a las ' +
          datos.hora +
          ".";

        alertController
          .create({
            message: mensaje,
            buttons: ["OK"],
          })
          .then((alert) => alert.present());
        return;
      }

      if ((this as any).loadingFlags[loadingflag]) return false;
      (this as any).loadingFlags[loadingflag] = true;

      const fotosCanopy = ud('fotoCanopy', []);
      const necesitaFoto = !fotosCanopy || !fotosCanopy.length;

      if (!await new Promise(function(resolve) { alertController
          .create({
            message: 'Va a indicar que ya se instaló el Canopy. ' + (necesitaFoto ? 'Para ello, deberá enviar una fotografía del mismo. ' : '') + '¿De acuerdo?',
            buttons: [{text:"CANCELAR", role: 'cancel', handler: function() { resolve(false); }}, { text: 'OK', handler: function() { resolve(true); }}],
          })
          .then((alert) => alert.present());
      })) {
              (this as any).loadingFlags[loadingflag] = false;
              return;
      }


      if (necesitaFoto) {
        (this as any).loadingFlags[loadingflag] = false; // desafortunado, pero es así - no se puede detectar el cancelar
        const fotoCanopy = await cyanGetPhoto();

        if (!fotoCanopy) {
          alertController
            .create({
              message: 'No se envió la foto del Canopy.',
              buttons: ["OK"],
            })
            .then((alert) => alert.present());
            (this as any).loadingFlags[loadingflag] = false;

            return;
        }
        (this as any).loadingFlags[loadingflag] = true;
        const r = await cyanRequest('pcsl/fotoCanopy', {
            isPost: true,
            retries: 3,
            needsAuth: true,
            timeout: 60000,
            isModal: false,
            files: { fichero: fotoCanopy }
          });

        if (r.ok) {
          store.commit("patchUserData", r);
        } else {
            (this as any).loadingFlags[loadingflag] = false;
            return;
        }

      }

      const place = await obtenerUbicacion();

      let lon, lat;

      if (place === false) {
        lon = "";
        lat = "";

      } else {
        lon = (place as any).coords.longitude;
        lat = (place as any).coords.latitude;
      }

      const r = await cyanRequest('pcsl/canopy', {
        isPost: true,
        retries: 3,
        needsAuth: true,
        isModal: false,
        params: {
          lat,
          lon,
        }});

      if (r.ok) {
        store.commit("patchUserData", r);
      }

      (this as any).loadingFlags[loadingflag] = false;

    },

    async geoAccionBoton(accion: string, ruta: string) {
      const piezasRuta = ruta.split("/");
      const loadingflag = piezasRuta.join("");
      const bandera = piezasRuta.pop();

      const datos = ud(bandera as string);

      if (datos) {
        const mensaje =
          accion.charAt(0).toUpperCase() +
          accion.slice(1) +
          " a las " +
          datos.hora +
          ".";

        alertController
          .create({
            message: mensaje,
            buttons: ["OK"],
          })
          .then((alert) => alert.present());
        return;
      }
      if ((this as any).loadingFlags[loadingflag]) return false;
      (this as any).loadingFlags[loadingflag] = true;

      const mensajeConfirmar = "Va a indicar que " + accion + ". ¿Es correcto?";
      const mensajeNoUbicado = '';
/*        "No se pudo obtener su ubicación. Puede indicar que " +
        accion +
        " sin indicar ubicación, o reintentarlo más tarde.";*/

      const result = await this.geoAccion(mensajeConfirmar, mensajeNoUbicado);

      if (result.confirmed) {
        const r = await cyanRequest(ruta, {
          isPost: true,
          retries: 3,
          needsAuth: true,
          isModal: false,
          params: {
            lat: result.lat,
            lon: result.lon,
          },
        });

        if (r.ok) {
          store.commit("patchUserData", r);
        }
      }

      (this as any).loadingFlags[loadingflag] = false;
    },
  },
});
