<template>
  <div class="streamlined-button">
    <div class="square">
      <img
        :src="'assets/streamlined/' + icon + '.svg'"
        :class="['boton', flip ? 'inactivo' : 'activo']"
      />
    </div>
    <div class="square">
      <img
        v-if="back"
        :src="'assets/streamlined/' + back + '.svg'"
        :class="['boton', flip ? 'activo' : 'inactivo']"
        rel="preload"
      />
    </div>
    <div class="square" v-if="withSpinner">
      <img
        :src="'assets/streamlined/SPINNER' + ((azul??false) ? '_AZUL' : '') + '.svg'"
        :class="['boton-spinner', 'loading', loading ? '' : 'hidden']"
        rel="preload"
      />
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StreamlinedButton",
  components: {},
  setup() {
    return {};
  },
  computed: {},
  methods: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
    back: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    azul: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
    withSpinner: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped>
.streamlined-button {
  position: relative;
  cursor: pointer;
}
.square {
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.square:after, .streamlined-button:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loading {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.inactivo {
  opacity: 0;
}

.activo {
  opacity: 1;
}
.boton {
  transition: opacity 0.5s;
}
.boton-spinner {
  transition: opacity 0.2s;
}
.hidden {
  opacity: 0;
}
</style>